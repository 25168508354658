import { encodeOpts as paramsSerializer } from '@/utils/encode-opts';
import { STORE_DISCOUNT_TYPES } from '../utils/constants';

const storesApiFactory = (axios) => ({
  getStoreComponent: (componentId) => axios.get(`/store/components/${componentId}`),
  getStoreDistributor: (storeUrl) => axios.get(`/stores/distributor/${storeUrl}`),
  getStoreShippingAddresses: async (storeId) =>
    (await axios.get(`/stores/${storeId}/shipping-addresses`, { params: { limit: 'all' }, paramsSerializer })).data
      .data,
  getPageHeadTags: (storeUrl, urlSlug, additionalSlug, options = {}) =>
    axios.post(`/stores/${storeUrl}/headtags`, {
      additionalSlug,
      ...options,
      urlSlug: urlSlug || 'undefined',
      applyVersion: true
    }),
  getStoreSlugPage: ({
    storeUrl,
    urlSlug,
    additionalSlug,
    categorySlug,
    customer,
    query = {},
    params = {},
    ...remainOptions
  }) => {
    return axios.post(`/stores/${storeUrl}/pages`, {
      additionalSlug,
      query,
      customer,
      categorySlug,
      urlSlug: urlSlug || 'undefined',
      params: { additionalSlug, urlSlug: urlSlug || 'undefined' },
      ...params,
      ...remainOptions,
    });
  },
  getStorePageDetails: async (body, axios = axios) => {
    return (await axios.post(`/stores/${body.storeUrl}/page-details`, body)).data;
  },

  getReportSettings: async (storeId, reportId, params = {}) =>
    (await axios.get(`/stores/${storeId}/reports/${reportId}/settings`, { params, paramsSerializer })).data,

  getCategories: async (storeId, params = {}) =>
    (await axios.get(`/stores/${storeId}/categories`, { params, paramsSerializer })).data,

  getRootCategories: async (storeId, categoryType) =>
    (await axios.get(`/stores/${storeId}/category-type/${categoryType}/root-categories`)).data,

  getStorePagesByCategory: async (categoryId, params = {}) => {
    const options = { params, paramsSerializer };
    return (await axios.get(`/categories/${categoryId}/store-pages`, options)).data;
  },

  getStoreSpecials: async (storeId, params = {}) => {
    params.time = Date.now();
    params.type = STORE_DISCOUNT_TYPES.SPECIALS;
    const options = { params, paramsSerializer };
    return (await axios.get(`/stores/${storeId}/codes`, options)).data;
  },

  getStorePages: async (storeId, params = {}) => {
    const options = { params, paramsSerializer };
    return (await axios.get(`/pages/store/${storeId}`, options)).data;
  },

  sendStoreContactForm: async (storeId, formId, params) => {
    return (await axios.post(`/stores/${storeId}/contact-form/${formId}`, params)).data;
  },

  getStoreArtworks: async (storeId, params) => (await axios.get(`/stores/${storeId}/artworks`, params)).data.data,

  async themesSearch(storeId, query, limit = 5) {
    const params = { query, type: [{ val: 'theme' }], limit };
    return (await this.getStorePages(storeId, params)).data;
  },

  async designsSearch(storeId, query, limit = 5) {
    const params = { query, limit };
    return (await axios.get(`/store/${storeId}/artworks`, params)).data;
  },

  async blogsSearch(storeId, query, limit = 5) {
    const params = { query, type: [{ val: 'blog' }], limit };
    return (await this.getStorePages(storeId, params)).data;
  },
  getThemeCategories: async (params) => {
    return (await axios.get('/categories-by', { params, paramsSerializer })).data;
  },

  productsQuickSearch: async (storeId, queryParams, limit = 5) => {
    const url = `/stores/${storeId}/products-quick-search`;
    const fields = ['productName', 'productId', 'imageDefaultItem.url', 'imageDefaultItem.urlSmall', 'slug'];
    const params = { ...queryParams, limit, fields };
    return (await axios.post(url, { params, paramsSerializer })).data;
  },
  getPageRedirectUrl: async (storeId, redirectUrl) => {
    const params = { redirectUrl };
    return (await axios.get(`/stores/${storeId}/redirect-url`, { params, paramsSerializer })).data;
  },
  getStoreRoles: async (params = {}) =>
    (
      await axios.get(`/store-roles`, {
        params: params,
        paramsSerializer,
      })
    ).data,
  deleteCustomer: async (storeId, customerId) => await axios.delete(`/stores/${storeId}/customers/${customerId}`),
  loadReferenceFields: async (storeId, params) =>
    (
      await axios.get(`/stores/${storeId}/reference-fields`, {
        params: params,
        paramsSerializer,
      })
    ).data,
});

export { storesApiFactory };
