import LogRocket from 'logrocket';

export default ({ app }, inject) => {
  const logRocketRoutes = ['/checkout', '/payments/_orderId', '/proof/_id'];

  const shouldInitLogRocket = (route) => {
    return logRocketRoutes.some((path) => {
      if (path.includes('_orderId')) {
        const regex = new RegExp('^/payments/[^/]+$');
        return regex.test(route.fullPath);
      }
      return route.fullPath === path;
    });
  };

  app.router.afterEach((to, from) => {
    if (process.env.NODE_ENV === 'production' && !process.env.IS_BOT_SERVER && shouldInitLogRocket(to)) {
      LogRocket.init('wzhqhl/mpower-promo');
      inject('logRocket', LogRocket);
      console.log(`LogRocket initialized on ${to.fullPath}`);
    }
  });
};
