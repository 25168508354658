import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _79aac1d1 = () => interopDefault(import('../client/pages/cart/index.vue' /* webpackChunkName: "" */))
const _5696fb1f = () => interopDefault(import('../client/pages/products/no-minimum/_.vue' /* webpackChunkName: "" */))
const _5e0ea3b3 = () => interopDefault(import('../client/pages/products/closeout/_.vue' /* webpackChunkName: "" */))
const _dda9349c = () => interopDefault(import('../client/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _3ed0d0de = () => interopDefault(import('../client/pages/cartitemsadded/index.vue' /* webpackChunkName: "pages/cartitemsadded/index" */))
const _3414a7bc = () => interopDefault(import('../client/pages/changepassword/index.vue' /* webpackChunkName: "pages/changepassword/index" */))
const _c530e6a6 = () => interopDefault(import('../client/pages/customer-artwork-proof/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/index" */))
const _0e7cd00c = () => interopDefault(import('../client/pages/designs/index.vue' /* webpackChunkName: "pages/designs/index" */))
const _9238e066 = () => interopDefault(import('../client/pages/facets/index.vue' /* webpackChunkName: "pages/facets/index" */))
const _0f3d16dc = () => interopDefault(import('../client/pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _1031a06f = () => interopDefault(import('../client/pages/forgotpassword/index.vue' /* webpackChunkName: "pages/forgotpassword/index" */))
const _39d5298a = () => interopDefault(import('../client/pages/health.vue' /* webpackChunkName: "pages/health" */))
const _02e0af4d = () => interopDefault(import('../client/pages/not-found/index.vue' /* webpackChunkName: "pages/not-found/index" */))
const _53c1d2ee = () => interopDefault(import('../client/pages/proof/index.vue' /* webpackChunkName: "pages/proof/index" */))
const _4b136649 = () => interopDefault(import('../client/pages/proofitemsadded/index.vue' /* webpackChunkName: "pages/proofitemsadded/index" */))
const _574c0f81 = () => interopDefault(import('../client/pages/quoteitemsadded/index.vue' /* webpackChunkName: "pages/quoteitemsadded/index" */))
const _4c84c4af = () => interopDefault(import('../client/pages/resetpassword/index.vue' /* webpackChunkName: "pages/resetpassword/index" */))
const _307988ca = () => interopDefault(import('../client/pages/sampleitemsadded/index.vue' /* webpackChunkName: "pages/sampleitemsadded/index" */))
const _b1c0235a = () => interopDefault(import('../client/pages/signin/index.vue' /* webpackChunkName: "pages/signin/index" */))
const _aa3d1a14 = () => interopDefault(import('../client/pages/signout/index.vue' /* webpackChunkName: "pages/signout/index" */))
const _2913e8ac = () => interopDefault(import('../client/pages/suppliers/index.vue' /* webpackChunkName: "pages/suppliers/index" */))
const _9a71c8a8 = () => interopDefault(import('../client/pages/testy/index.vue' /* webpackChunkName: "pages/testy/index" */))
const _c31018cc = () => interopDefault(import('../client/pages/version.vue' /* webpackChunkName: "pages/version" */))
const _52fec371 = () => interopDefault(import('../client/pages/version-app/index.vue' /* webpackChunkName: "pages/version-app/index" */))
const _a262c85a = () => interopDefault(import('../client/pages/cat/GeneralProductView.vue' /* webpackChunkName: "pages/cat/GeneralProductView" */))
const _55011d93 = () => interopDefault(import('../client/pages/orders/products/index.vue' /* webpackChunkName: "pages/orders/products/index" */))
const _27a0089b = () => interopDefault(import('../client/pages/products/utils.js' /* webpackChunkName: "pages/products/utils" */))
const _1b1a26d6 = () => interopDefault(import('../client/pages/sso/facebook/delete.vue' /* webpackChunkName: "pages/sso/facebook/delete" */))
const _5618a034 = () => interopDefault(import('../client/pages/review/order/_orderId/product/_productId.vue' /* webpackChunkName: "pages/review/order/_orderId/product/_productId" */))
const _1061c276 = () => interopDefault(import('../client/pages/products/search/_.vue' /* webpackChunkName: "pages/products/search/_" */))
const _61901167 = () => interopDefault(import('../client/pages/blog/cat/_.vue' /* webpackChunkName: "pages/blog/cat/_" */))
const _26dd1c4a = () => interopDefault(import('../client/pages/approval/_orderId/index.vue' /* webpackChunkName: "pages/approval/_orderId/index" */))
const _3fa3600e = () => interopDefault(import('../client/pages/artproof/_productSlug/index.vue' /* webpackChunkName: "pages/artproof/_productSlug/index" */))
const _3926b053 = () => interopDefault(import('../client/pages/authors/_authorName/index.vue' /* webpackChunkName: "pages/authors/_authorName/index" */))
const _82a6ae52 = () => interopDefault(import('../client/pages/components/_componentId/index.vue' /* webpackChunkName: "pages/components/_componentId/index" */))
const _78ed9686 = () => interopDefault(import('../client/pages/configureproduct/_productSlug/index.vue' /* webpackChunkName: "pages/configureproduct/_productSlug/index" */))
const _71beedd0 = () => interopDefault(import('../client/pages/customer-artwork-proof/_id/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/_id/index" */))
const _3726e2b8 = () => interopDefault(import('../client/pages/designer/_productSlug/index.vue' /* webpackChunkName: "pages/designer/_productSlug/index" */))
const _205c2fb5 = () => interopDefault(import('../client/pages/designs/_slug/index.vue' /* webpackChunkName: "pages/designs/_slug/index" */))
const _82a88570 = () => interopDefault(import('../client/pages/facets/_slug/index.vue' /* webpackChunkName: "pages/facets/_slug/index" */))
const _1fb5762e = () => interopDefault(import('../client/pages/largequote/_productSlug/index.vue' /* webpackChunkName: "pages/largequote/_productSlug/index" */))
const _85d5ac7e = () => interopDefault(import('../client/pages/orders/_id/index.vue' /* webpackChunkName: "pages/orders/_id/index" */))
const _10e9bcb1 = () => interopDefault(import('../client/pages/payments/_orderId/index.vue' /* webpackChunkName: "pages/payments/_orderId/index" */))
const _0f51bea4 = () => interopDefault(import('../client/pages/presentation/_orderId/index.vue' /* webpackChunkName: "pages/presentation/_orderId/index" */))
const _19bfc4ee = () => interopDefault(import('../client/pages/presentations/_id/index.vue' /* webpackChunkName: "pages/presentations/_id/index" */))
const _91af2fc4 = () => interopDefault(import('../client/pages/printinvoice/_orderId/index.vue' /* webpackChunkName: "pages/printinvoice/_orderId/index" */))
const _240d2f90 = () => interopDefault(import('../client/pages/products/_slug/index.vue' /* webpackChunkName: "pages/products/_slug/index" */))
const _6307c4f4 = () => interopDefault(import('../client/pages/proof/_id/index.vue' /* webpackChunkName: "pages/proof/_id/index" */))
const _71aec1e0 = () => interopDefault(import('../client/pages/quickquote/_productSlug/index.vue' /* webpackChunkName: "pages/quickquote/_productSlug/index" */))
const _a4d5a42a = () => interopDefault(import('../client/pages/reorder/_id/index.vue' /* webpackChunkName: "pages/reorder/_id/index" */))
const _5fdd0b85 = () => interopDefault(import('../client/pages/sample/_productSlug/index.vue' /* webpackChunkName: "pages/sample/_productSlug/index" */))
const _22e1b324 = () => interopDefault(import('../client/pages/sso/_service.vue' /* webpackChunkName: "pages/sso/_service" */))
const _555b876a = () => interopDefault(import('../client/pages/cat/_slug/brand.vue' /* webpackChunkName: "pages/cat/_slug/brand" */))
const _254d8bc0 = () => interopDefault(import('../client/pages/orders/_id/track-shipment/index.vue' /* webpackChunkName: "pages/orders/_id/track-shipment/index" */))
const _d30b064e = () => interopDefault(import('../client/pages/products/_slug/reviews.vue' /* webpackChunkName: "pages/products/_slug/reviews" */))
const _43e7c935 = () => interopDefault(import('../client/pages/facets/_slug/_facet/index.vue' /* webpackChunkName: "pages/facets/_slug/_facet/index" */))
const _2e6a9faa = () => interopDefault(import('../client/pages/supplier/_psCode/_.vue' /* webpackChunkName: "pages/supplier/_psCode/_" */))
const _ae6d09ba = () => interopDefault(import('../client/pages/cat/_slug/_.vue' /* webpackChunkName: "pages/cat/_slug/_" */))
const _2529a321 = () => interopDefault(import('../client/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/box/cart",
    component: _79aac1d1,
    name: "box/cart"
  }, {
    path: "/quote/cart",
    component: _79aac1d1,
    name: "quote/cart"
  }, {
    path: "/sample/cart",
    component: _79aac1d1,
    name: "sample/cart"
  }, {
    path: "/proof/cart",
    component: _79aac1d1,
    name: "proof/cart"
  }, {
    path: "/boxcart",
    component: _79aac1d1,
    name: "boxcart"
  }, {
    path: "/quotecart",
    component: _79aac1d1,
    name: "quotecart"
  }, {
    path: "/samplecart",
    component: _79aac1d1,
    name: "samplecart"
  }, {
    path: "/proofcart",
    component: _79aac1d1,
    name: "proofcart"
  }, {
    path: "/products/no-minimum",
    component: _5696fb1f,
    name: "products-no-minimum"
  }, {
    path: "/products/closeout",
    component: _5e0ea3b3,
    name: "products-closeout"
  }, {
    path: "/account",
    component: _dda9349c,
    name: "account"
  }, {
    path: "/cart",
    component: _79aac1d1,
    name: "cart"
  }, {
    path: "/cartitemsadded",
    component: _3ed0d0de,
    name: "cartitemsadded"
  }, {
    path: "/changepassword",
    component: _3414a7bc,
    name: "changepassword"
  }, {
    path: "/customer-artwork-proof",
    component: _c530e6a6,
    name: "customer-artwork-proof"
  }, {
    path: "/designs",
    component: _0e7cd00c,
    name: "designs"
  }, {
    path: "/facets",
    component: _9238e066,
    name: "facets"
  }, {
    path: "/favorites",
    component: _0f3d16dc,
    name: "favorites"
  }, {
    path: "/forgotpassword",
    component: _1031a06f,
    name: "forgotpassword"
  }, {
    path: "/health",
    component: _39d5298a,
    name: "health"
  }, {
    path: "/not-found",
    component: _02e0af4d,
    name: "not-found"
  }, {
    path: "/proof",
    component: _53c1d2ee,
    name: "proof"
  }, {
    path: "/proofitemsadded",
    component: _4b136649,
    name: "proofitemsadded"
  }, {
    path: "/quoteitemsadded",
    component: _574c0f81,
    name: "quoteitemsadded"
  }, {
    path: "/resetpassword",
    component: _4c84c4af,
    name: "resetpassword"
  }, {
    path: "/sampleitemsadded",
    component: _307988ca,
    name: "sampleitemsadded"
  }, {
    path: "/signin",
    component: _b1c0235a,
    name: "signin"
  }, {
    path: "/signout",
    component: _aa3d1a14,
    name: "signout"
  }, {
    path: "/suppliers",
    component: _2913e8ac,
    name: "suppliers"
  }, {
    path: "/testy",
    component: _9a71c8a8,
    name: "testy"
  }, {
    path: "/version",
    component: _c31018cc,
    name: "version"
  }, {
    path: "/version-app",
    component: _52fec371,
    name: "version-app"
  }, {
    path: "/cat/GeneralProductView",
    component: _a262c85a,
    name: "cat-GeneralProductView"
  }, {
    path: "/orders/products",
    component: _55011d93,
    name: "orders-products"
  }, {
    path: "/products/utils",
    component: _27a0089b,
    name: "products-utils"
  }, {
    path: "/sso/facebook/delete",
    component: _1b1a26d6,
    name: "sso-facebook-delete"
  }, {
    path: "/review/order/:orderId?/product/:productId?",
    component: _5618a034,
    name: "review-order-orderId-product-productId"
  }, {
    path: "/products/search/*",
    component: _1061c276,
    name: "products-search-all"
  }, {
    path: "/products/no-minimum/*",
    component: _5696fb1f,
    name: "products-no-minimum-all"
  }, {
    path: "/products/closeout/*",
    component: _5e0ea3b3,
    name: "products-closeout-all"
  }, {
    path: "/blog/cat/*",
    component: _61901167,
    name: "blog-cat-all"
  }, {
    path: "/approval/:orderId",
    component: _26dd1c4a,
    name: "approval-orderId"
  }, {
    path: "/artproof/:productSlug",
    component: _3fa3600e,
    name: "artproof-productSlug"
  }, {
    path: "/authors/:authorName",
    component: _3926b053,
    name: "authors-authorName"
  }, {
    path: "/components/:componentId",
    component: _82a6ae52,
    name: "components-componentId"
  }, {
    path: "/configureproduct/:productSlug",
    component: _78ed9686,
    name: "configureproduct-productSlug"
  }, {
    path: "/customer-artwork-proof/:id",
    component: _71beedd0,
    name: "customer-artwork-proof-id"
  }, {
    path: "/designer/:productSlug",
    component: _3726e2b8,
    name: "designer-productSlug"
  }, {
    path: "/designs/:slug",
    component: _205c2fb5,
    name: "designs-slug"
  }, {
    path: "/facets/:slug",
    component: _82a88570,
    name: "facets-slug"
  }, {
    path: "/largequote/:productSlug",
    component: _1fb5762e,
    name: "largequote-productSlug"
  }, {
    path: "/orders/:id",
    component: _85d5ac7e,
    name: "orders-id"
  }, {
    path: "/payments/:orderId",
    component: _10e9bcb1,
    name: "payments-orderId"
  }, {
    path: "/presentation/:orderId",
    component: _0f51bea4,
    name: "presentation-orderId"
  }, {
    path: "/presentations/:id",
    component: _19bfc4ee,
    name: "presentations-id"
  }, {
    path: "/printinvoice/:orderId",
    component: _91af2fc4,
    name: "printinvoice-orderId"
  }, {
    path: "/products/:slug",
    component: _240d2f90,
    name: "products-slug"
  }, {
    path: "/proof/:id",
    component: _6307c4f4,
    name: "proof-id"
  }, {
    path: "/quickquote/:productSlug",
    component: _71aec1e0,
    name: "quickquote-productSlug"
  }, {
    path: "/reorder/:id",
    component: _a4d5a42a,
    name: "reorder-id"
  }, {
    path: "/sample/:productSlug",
    component: _5fdd0b85,
    name: "sample-productSlug"
  }, {
    path: "/sso/:service?",
    component: _22e1b324,
    name: "sso-service"
  }, {
    path: "/cat/:slug?/brand",
    component: _555b876a,
    name: "cat-slug-brand"
  }, {
    path: "/orders/:id?/track-shipment",
    component: _254d8bc0,
    name: "orders-id-track-shipment"
  }, {
    path: "/products/:slug?/reviews",
    component: _d30b064e,
    name: "products-slug-reviews"
  }, {
    path: "/facets/:slug/:facet",
    component: _43e7c935,
    name: "facets-slug-facet"
  }, {
    path: "/supplier/:psCode?/*",
    component: _2e6a9faa,
    name: "supplier-psCode-all"
  }, {
    path: "/cat/:slug?/*",
    component: _ae6d09ba,
    name: "cat-slug-all"
  }, {
    path: "/*",
    component: _2529a321,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
