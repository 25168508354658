export const reservedKeys = [
  'suppliersToFilter',
  'search',
  'closeout',
  'query',
  'page',
  'price',
  'name',
  'sortBy',
  'supplier',
].map((el) => el.toLowerCase());

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const SHIPMENT_COUNTRIES = {
  USA: 'US',
  Canada: 'CA',
  Mexico: 'MX',
  UnitedKingdom: 'GB',
};
export const SHIPMENT_COUNTRIES_LIST = Object.values(SHIPMENT_COUNTRIES).map((e) => e.toLowerCase());

export const CUSTOMER_DEVICE_SOURCE = {
  CHECKOUT: 'Checkout',
  CREATE_ACCOUNT: 'Create Account',
  CREATE_ORDER: 'Create Order',
};

export const CART_BUTTONS = {
  ADD_TO_CART: 1,
  CUSTOMIZE: 2,
  DESIGNER: 3,
  QUOTE_REQUEST: 4,
  ORDER_SAMPLE: 5,
  REQUEST_PROOF: 6,
};
export const BUTTONS_CONFIG_TYPES = {
  ADD_TO_CART: 'addToCartButton',
  CUSTOMIZE: 'configureButton',
  DESIGNER: 'designButton',
  QUOTE_REQUEST: 'quoteButton',
  ORDER_SAMPLE: 'sampleButton',
  REQUEST_PROOF: 'artproofButton',
  SEE_DECORATED_PRICING: 'seeDecoratedPricingButton',
  ADD_TO_BOX: 'addToBoxButton',
};

export const STORE_DISCOUNT_TYPES = {
  FLAT_DOLLAR_AMOUNT: 'Flat Dollar Amount',
  FLAT_PERCENTAGE: 'Flat Percentage',
  ALLOWANCE: 'Allowance',
  CREDIT: 'Credit',
  RESTRICTION_CODE: 'Restriction Code',
  SPECIALS: 'Specials',
};
